<template>
  <valorem-modal
    ref="modal"
    :titulo-modal="$t('GERAL.DETALHES')"
    @cancelar="fecharModal"
    tamanho="md"
    esconderRodape
  >
    <div class="row">
      <div class="col-12">
        <valorem-tabela
          v-model="modalData"
          :colunas="tabela.colunas"
          ref="tabela"
          semPaginacao
        >
        </valorem-tabela>
      </div>
    </div>
  </valorem-modal>
</template>
<script>
import helpers from "../../../common/utils/helpers";
import cloneDeep from "lodash.clonedeep";

export default {
  name: "ModalDetalhes",
  data() {
    return {
      opcoes: {
        tipos: helpers.TipoContato,
      },
      formModal: {},
      tabela: {
        colunas: [
          {
            key: "statusMensagem",
            label: this.$t("GERAL.DETALHES"),
          },
          { key: "nit", label: this.$t("TRANSACOES.NIT") },
        ],
      },
    };
  },
  props: {
    modalData: {
      required: true,
    },
  },
  methods: {
    abrirModal: function () {
      this.$refs.modal.abrirModal();
    },
    fecharModal: function () {
      this.$emit("cancelar");
      this.$refs.modal.fecharModal();
    },
    salvar: function () {
      this.confirmar(
        this.$t("GERAL.CONFIRMAR"),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        if (!this.validarFormulario()) return false;

        this.$emit("salvar", this.formModal);
      });
    },
  },
  watch: {
    form: {
      handler() {
        this.formModal = cloneDeep(this.form);
      },
      deep: true,
    },
  },
};
</script>
